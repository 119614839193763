import I18n from '../i18n-settings.js.erb'

$(document).on('turbolinks:load', () => {
  // Set dropdowns and weight input for first page of new inspections
  // ---------------------------------------------------------------------
  const selectEstate = $('#inspection_estate')
  const selectBuilding = $('#inspection_building')
  const selectFloor = $('#inspection_floor_id')
  const buildings = selectBuilding.html()
  const floors = selectFloor.html()

  const selectServiceGroupCategory = $('#inspection_service_group_category')
  const selectServiceGroup = $('#inspection_service_group_id')
  const serviceGroups = selectServiceGroup.html()
  const serviceGroupWeights = $('#service_group_weights').data('id-weights')

  const emptyOption = "<option value='' selected='selected'></option>"

  // Disable secondary/tertiary selects of cascades
  if (selectEstate.val() === '') { selectBuilding.prop('disabled', true) }
  if (selectBuilding.val() === '') { selectFloor.prop('disabled', true) }
  if (selectServiceGroup.val() === '') { selectServiceGroup.prop('disabled', true) }

  // Populate buildings
  const selectedBuilding = selectBuilding.find('option:selected').val()
  selectEstate.change(function() {
    const selectedEstate = selectEstate.find('option:selected').val()
    const options = $(buildings).filter(`optgroup[label='${selectedEstate}']`).html()
    const numberOfBuildings = options ? (options.match(/value/g) || []).length : 0
    if (options) {
      selectBuilding.prop('disabled', false)
      selectBuilding.html(options)
      // If nothing is selected yet, add empty option, so User has to select right value first, but only when more than one Building is selectable
      if (!(selectedBuilding > 0) && (numberOfBuildings !== 1)) {
        selectBuilding.prepend(emptyOption)
      }
      if (selectBuilding.val() === '') { selectFloor.prop('disabled', true) }
    } else {
      selectBuilding.empty()
    }
    return populateSelectFloor()
  })

  // Populate floors
  selectBuilding.change(function() {
    if (selectBuilding.val() !== '') { selectFloor.prop('disabled', false) }
    return populateSelectFloor()
  })

  const populateSelectFloor = () => {
    const building = selectBuilding.find(':selected').val()
    const options = $(floors).filter(`optgroup[label='${building}']`).html()
    const floor = selectFloor.find('option:selected').val()
    const numberOfFloors = options ? (options.match(/value/g) || []).length : 0
    if (options) {
      selectFloor.prop('disabled', false)
      selectFloor.html(options)
      // If nothing is selected yet, add empty option, so User has to select right value first, but only when more than one Floor is selectable
      if (!(floor > 0) && (numberOfFloors !== 1)) {
        return selectFloor.prepend(emptyOption)
      }
    } else {
      return selectFloor.empty()
    }
  }

  // Populate service groups
  const selectedServiceGroup = selectServiceGroup.find('option:selected').val()
  selectServiceGroupCategory.change(function() {
    const selectedServiceGroupCategory = selectServiceGroupCategory.find('option:selected').val()
    const options = $(serviceGroups).filter(`optgroup[label='${selectedServiceGroupCategory}']`).html()
    const numberOfServiceGroups = options ? (options.match(/value/g) || []).length : 0
    if (options) {
      selectServiceGroup.prop('disabled', false)
      selectServiceGroup.html(options)
      // If nothing is selected yet, add empty option, so User has to select right value first, but only when more than one ServiceGroup is selectable
      if (!(selectedServiceGroup > 0) && (numberOfServiceGroups !== 1)) {
        selectServiceGroup.prepend(emptyOption)
      }
      if (selectServiceGroupCategory.val() === '') { return selectServiceGroup.prop('disabled', true) }
    } else {
      return selectServiceGroup.empty()
    }
  })

  // Populate service group weight
  selectServiceGroupCategory.change(() => setInspectionServiceGroupWeight())
  selectServiceGroup.change(() => setInspectionServiceGroupWeight())

  const setInspectionServiceGroupWeight = () => {
    let inspectionServiceGroupWeight
    const serviceGroupId = selectServiceGroup.find(':selected').val()
    for (let id in serviceGroupWeights) {
      const weight = serviceGroupWeights[id]
      if (id === serviceGroupId) {
        inspectionServiceGroupWeight = weight
      }
    }
    if (inspectionServiceGroupWeight) {
      // Set disabled text field
      $('#service_group_weight_showonly').val(inspectionServiceGroupWeight)
      // Set hidden field
      return $('#inspection_service_group_weight').val(inspectionServiceGroupWeight)
    } else {
      // Reset that stuff
      $('#service_group_weight_showonly').val('')
      return $('#inspection_service_group_weight').val('')
    }
  }

  // Also trigger everything after page (re)load, e.g. validation fail
  if (selectEstate) {
    selectEstate.trigger('change')
  }
  if (selectServiceGroupCategory) {
    selectServiceGroupCategory.trigger('change')
  }

  // Set points_actual for evaluation page of new Inspections
  // ---------------------------------------------------------------------

  // Global
  const selectPercentGlobal = $('a.is_global_percent')
  const selectPercent = $('#inspected_services input.slider')

  // Global
  selectPercentGlobal.click((event) => {
    event.preventDefault()
    const percent = event.target.dataset.percent
    selectPercent.each(function() {
      $(this).bootstrapSlider('setValue', percent)
      $(this).change()
      // Adapt input size for points_actual
      $(this).attr('size', $(this).val().length)
    })
  })

  // Single
  $('#inspected_services .points_actual input').each(function() {
    $(this).prop('readonly', 'readonly')
    // Adapt input size for points_actual
    return $(this).attr('size', $(this).val().length)
  })

  selectPercent.change(function() {
    const percent = $(this).val()
    const pointsTarget = $(this).closest('.body').find('.points_target input').val()
    const inputPointsActual = $(this).closest('.body').find('.points_actual input')

    // Calculate points_actual
    let pointsActual = ((pointsTarget * percent) / 100)
    // Try to localize
    pointsActual = pointsActual.toLocaleString(I18n.locale)
    // pointsActual = pointsActual.toFixed(1)
    inputPointsActual.val(pointsActual)
    if (percent === '100') {
      inputPointsActual.closest('.service').removeClass('not_inspected defective')
      $(this).closest('.body').find('input[name*="not_inspected"]').val('')
    } else {
      inputPointsActual.closest('.service').addClass('defective')
      inputPointsActual.closest('.service').removeClass('not_inspected')
      $(this).closest('.body').find('input[name*="not_inspected"]').val('')
    }
    // Adapt input size for pointsActual
    return inputPointsActual.attr('size', inputPointsActual.val().length)
  })

  // Handle toggle not inspected
  const disableServiceButton = $('#inspected_services .toggle-disabled button.disable_service')
  const enableServiceButton = $('#inspected_services .toggle-disabled button.enable_service')
  disableServiceButton.click(function() {
    const inputNotInspected = $(this).closest('.body').find('input[name*="not_inspected"]')
    const pointsTarget = $(this).closest('.body').find('.points_target input').val()
    const inputPointsActual = $(this).closest('.body').find('.points_actual input')
    inputNotInspected.val(true)
    inputPointsActual.val(pointsTarget)
    inputPointsActual.closest('.service').addClass('not_inspected')
    $(this).hide()
    return $(this).closest('.body').find('button.enable_service').show()
  })
  enableServiceButton.click(function() {
    const inputNotInspected = $(this).closest('.body').find('input[name*="not_inspected"]')
    const pointsTarget = $(this).closest('.body').find('.points_target input').val()
    const inputPointsActual = $(this).closest('.body').find('.points_actual input')
    inputNotInspected.val(false)
    inputPointsActual.val(pointsTarget)
    inputPointsActual.closest('.service').removeClass('not_inspected')
    $(this).closest('.body').find('button.disable_service').show()
    return $(this).hide()
  })

  // Set clockpicker for Inspection corrections
  // ---------------------------------------------------------------------

  $('.clockpicker').clockpicker({
    autoclose: 'true',
    placement: 'top',
    default: 'now',
    align: 'left',
    donetext: '✔'
  })
})
