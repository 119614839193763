# Clickable table rows with link
# ---------------------------------------------------------------------

$(document).on 'turbolinks:load', ->
  $('.linkable-row').click ->
    if Turbolinks
      Turbolinks.visit $(this).data('href')
    else
      window.document.location = $(this).data('href')


# Off Canvas navigation
# ---------------------------------------------------------------------

# Fold out
$(document).on 'turbolinks:load', ->
  openSidebarDropdown()
  $(document).click ->
    openSidebarDropdown()

openSidebarDropdown = ->
  $('.navmenu-nav .dropdown li').each ->
    if $(this).hasClass('active')
      $(this).closest('.dropdown').addClass('open')

# Keep dropdowns open (important)
$(document).on 'turbolinks:load', ->
  $('#page_content').on 'click', '.dropdown-toggle', ->
    $('.navmenu .dropdown').on 'hide.bs.dropdown', ->
      false

# Hide the toggle-nav button unless #sidebar is present
$(document).on 'turbolinks:load', ->
  unless $('#page_sidenav').length
    $('#toggle_sidenav').hide()

# Toggle collapse button
$(document).on 'turbolinks:load', ->
  $('#page_sidenav').on 'show.bs.offcanvas', (e) ->
    $('#toggle_sidenav .hamburger_button').removeClass('collapsed')
    return
  $('#page_sidenav').on 'hide.bs.offcanvas', (e) ->
    $('#toggle_sidenav .hamburger_button').addClass('collapsed')
    return

# Hide offcanvas on sidenav click, so swiping back in iOS doesn't crap
# out the page
$(document).on 'turbolinks:load', ->
  $('#page_sidenav').on 'shown.bs.offcanvas', (e) ->
    $('#page_sidenav a:not(.dropdown-toggle)').on 'click', (e) ->
      $('#page_sidenav').offcanvas('hide')

# Permanent #page_sidenav (turbolinks)
# $(document).on 'turbolinks:load', ->
#   $('#page_sidenav a').click ->
#     $('#page_sidenav li').removeClass('active')
#     unless $(this).parent('li').hasClass('dropdown')
#       $(this).parent('li').addClass('active')
#     # unless $(this).closest('li.dropdown').hasClass('open')
#     #   $(this).closest('li.dropdown').addClass('gorka')
#
#   $('#page_sidenav li.dropdown a').on 'click', (event) ->
#     # $('#page_sidenav li.dropdown').removeClass('open')
#     $(this).parent('li.dropdown').toggleClass('open')
#     return


# Prevent links where Turbolinks is disabled or data-remote="true" to open new window in mobile Safari standalone Webapps (Homescreen links)
# https://gist.github.com/kylebarrow/1042026
# ---------------------------------------------------------------------

$(document).on 'turbolinks:load', ->
  # For iOS Web apps, so they do not open in new window
  if 'standalone' of window.navigator and window.navigator.standalone
    # If you want to prevent remote links in standalone web apps opening Mobile Safari, change 'remotes' to true
    link = undefined
    remotes = true
    document.addEventListener 'click', ((event) ->
      link = event.target
      # Bubble up until we hit link or top HTML element. Warning: BODY element is not compulsory so better to stop on HTML
      while link.nodeName != 'A' and link.nodeName != 'HTML'
        link = link.parentNode
      if 'href' of link and link.href.indexOf('http') != -1 and (link.href.indexOf(document.location.host) != -1 or remotes) and event.target.constructor.name != 'HTMLAnchorElement'
        event.preventDefault()
        # do not redirect page on data-remote links
        (document.location.href = link.href) unless $(link).data('remote') == true
      return
    ), false


# Tooltips
# ---------------------------------------------------------------------

$(document).on 'turbolinks:load', ->
  $("[data-toggle=\"tooltip\"]").tooltip()


# Filter search
# ---------------------------------------------------------------------

# Sets the filter search button active
$(document).on 'turbolinks:load', ->
  if $('#filter_search').hasClass('in')
    $('[aria-controls=\'filter_search\']').addClass('active')

# Sets the filter search button active
$(document).on 'turbolinks:load', ->
  $('#filter_search').on 'show.bs.collapse', ->
    $('[aria-controls=\'filter_search\']').addClass('active')
  $('#filter_search').on 'hide.bs.collapse', ->
    $('[aria-controls=\'filter_search\']').removeClass('active')


# Data confirm modals
# ---------------------------------------------------------------------

$.rails.allowAction = (element) ->
  # The message is something like "Are you sure?"
  message = element.data('confirm')
  ok_text = element.data('ok')
  cancel_text = element.data('cancel')
  hint_text = element.data('hint')
  # If there's no message, there's no data-confirm attribute,
  # which means there's nothing to confirm
  return true unless message
  # Clone the clicked element (probably a delete link) so we can use it in the dialog box.
  $link = element.clone()
    # We don't necessarily want the same styling as the original link/button.
    .removeAttr('class')
    # We don't want to pop up another confirmation (recursion)
    .removeAttr('data-confirm')
    # We want a button
    .addClass('btn btn-danger confirm')
    # We want it to sound confirmy
    .html("#{ok_text}")

  # Create the modal box with the message
  modal_html = """
    <div id="confirmation_modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="confirmation_modal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">#{message}</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <p>#{hint_text}</p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-outline-dark" data-dismiss="modal" aria-hidden="true">#{cancel_text}</button>
          </div>
        </div>
      </div>
    </div>
    """
  $modal_html = $(modal_html)
  # Add the new button to the modal box
  $modal_html.find('.modal-footer').append($link)
  # Pop it up
  $modal_html.modal('show')
  # Prevent the original link from working
  return false

# Focus modal confirmation button
$(document).on 'turbolinks:load', ->
  $(document.body).on 'shown.bs.modal', '#confirmation_modal', (e) ->
    $('#confirmation_modal').find('.confirm').focus()
    return


# Remove :hover for touch devices globally
# ---------------------------------------------------------------------

# $(document).on 'turbolinks:load', ->
#   removeHover()


# Enable Organization switching inside modal
# ---------------------------------------------------------------------

# Append a modal dialog div to the HTML body which can be shown in case an #export_pdf_document_link is on a page an populated with the request path from above
$(document).on 'turbolinks:load', ->
  if $("a[data-toggle*='modal']").length > 0
    # Create the modal box
    # TODO add .modal-dialog-scrollable to .modal-dialog
    modal_html = """
    <div id="main_modal" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title"></h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
          </div>
        </div>
      </div>
    </div>
    """
    $modal_html = $(modal_html)
    $("body").append($modal_html)
    return false

$(document).on 'turbolinks:load', ->
  $('#main_modal').on 'show.bs.modal', (event) ->
    modal = $(this)
    button = event.relatedTarget
    modal.find('.modal-body').load button.href + '?modal_layout=true', (response, status, xhr) ->
      modal.find('.modal-title').html($(response).find('h1').text())
    return


# Fix (f)ugly iOS fixed positioning bug when using form elements
# ---------------------------------------------------------------------

$(document).on 'turbolinks:load', ->
  if 'ontouchstart' of window
    $('input, select, textarea').on 'focus', ->
      $('#page_headernav').css
        'position': 'absolute'
        'top': $('body').scrollTop()
      $('#page_sidenav').css
        'position': 'absolute'
        'top': $('body').scrollTop()
      focusing = true
      return
    $('input, select, textarea').on 'blur', ->
      $('#page_headernav').css
        'position': 'fixed'
        'top': 0
      $('#page_sidenav').css
        'position': 'fixed'
        'top': 0
      focusing = false
      return
    return
$(window).scroll ->
  if 'ontouchstart' of window
    unless typeof focusing == 'undefined'
      if focusing
        $('#page_headernav').css 'top': $(document).scrollTop()
        $('#page_sidenav').css 'top': $(document).scrollTop()
      return


# Asynchronous content loader
# ---------------------------------------------------------------------
$(document).on 'turbolinks:load', ->
  $('#page_content').on 'submit', 'form.async', ->
    content = """
      <div class="async_loader"></div>
      """
    $('.async_content').html(content)
    $('.async_content').fadeIn(50)


# Add active class to card headings
# ---------------------------------------------------------------------

$(document).on 'turbolinks:load', ->
  $('.card-collapse').on 'show.bs.collapse', ->
    $(this).siblings('.card-header').addClass 'active'
    return
  $('.card-collapse').on 'hide.bs.collapse', ->
    $(this).siblings('.card-header').removeClass 'active'
    return
  return


# Hide flash after x seconds
# ---------------------------------------------------------------------

$(document).on 'turbolinks:load', ->
  setTimeout (->
    $('#flash_message').removeClass('animate__fadeInDown').addClass('animate__fadeOutUp')
    return
  ), 5000
  return

$(document).on 'turbolinks:load', ->
  $("#flash_message button.close").click ->
    $(this).parent().removeClass('animate__fadeInDown').addClass('animate__fadeOutUp')


# Deep linking for Tabs
# ---------------------------------------------------------------------

$(document).on 'turbolinks:load', ->
  url = location.href.replace(/\/$/, '')
  if location.hash
    hash = url.split('#')
    $('#linkableTabs a[href="#' + hash[1] + '"]').tab 'show'
    url = location.href.replace(/\/#/, '#')
    history.replaceState null, null, url
  $('a[data-toggle="tab"]').on 'click', ->
    `var hash`
    newUrl = undefined
    hash = $(this).attr('href')
    if hash == '#home'
      newUrl = url.split('#')[0]
    else
      newUrl = url.split('#')[0] + hash
    newUrl += '/'
    history.replaceState null, null, newUrl
    return
  return


# Organization list search field
# ---------------------------------------------------------------------

$(document).on 'turbolinks:load', ->
  $(document).on 'shown.bs.modal', '#main_modal', (e) ->
    searchField = $('#organization-list-search')

    # Check if the search field exists in the modal
    if searchField.length > 0
      # Focus search field by pressing 'f'
      $(document).on 'keydown', (e) ->
        if e.keyCode == 70 && !searchField.is(':focus')
          searchField.focus()
          e.preventDefault()

      # Handle search form input
      searchField.on 'input', (e) ->
        searchTerm = e.target.value.toUpperCase()
        # Handle each list element
        $('.organization_selector li').each ->
          organizationName = $(this).find('.nameing').text().toUpperCase()
          $(this).toggle(organizationName.includes(searchTerm))

  # Clean up event handlers when the modal is hidden
  $(document).on 'hidden.bs.modal', '#main_modal', (e) ->
    $(document).off 'keydown'
    $('#organization-list-search').off 'input'


# Rating (stars)
# See rating_helper.rb
# ---------------------------------------------------------------------

$(document).on 'turbolinks:load', ->
  $('.rating.form').each ->
    rating_form_id = '#' + $(this).attr('id')
    rating_star_selector = rating_form_id + ' .rating_star'

    $(rating_star_selector).hover ->
      $(this).prevAll().addClass('full')
      $(this).nextAll().removeClass('full')
    , ->
      if $(rating_star_selector + ':checked').length > 0
        $(rating_star_selector + ':checked').prevAll().addClass('full')
        $(rating_star_selector + ':checked').nextAll().removeClass('full')
      else
        $(rating_star_selector).removeClass('full')

    $(rating_star_selector).click ->
      $(this).prevAll().addClass('full')
      $(this).nextAll().removeClass('full')

# Reset processing_time selects
# ---------------------------------------------------------------------

$(document).on 'turbolinks:load', ->
  $('#reset_processing_time_button').on 'click', ->
    # Reset all three processing_time select fields to its default value
    $('#processing_time_days').val('')
    $('#processing_time_hours').val('')
    $('#processing_time_minutes').val('')
    return
