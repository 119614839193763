$(document).on 'turbolinks:load', ->

  select_sgc     = $('.select_sg_sgc')
  select_sg      = $('.select_sg_sg')
  service_groups = select_sg.html()
  sg_weights     = $('#service_group_weights').data('id-weights')

  empty_option = select_sgc.find('option:first-child').clone().wrap('<option>').parent().html()

  # Disable secondary/tertiary selects of cascades
  select_sg.prop('disabled', true) if select_sg.val() == ''

  # Populate service groups
  selected_service_group = select_sg.find('option:selected').val()
  select_sgc.change ->
    selected_sgc = select_sgc.find('option:selected').val()
    options = $(service_groups).filter("optgroup[label='#{selected_sgc}']").html()
    if options
      select_sg.prop('disabled', false)
      select_sg.html(options)
      select_sg.prop('disabled', true) if select_sgc.val() == ''
    else
      select_sg.prop('disabled', true)
    select_sg.prepend(empty_option)  if select_sg.find('option:first-child').val() != ''
    select_sg[0].selectedIndex = 0 unless selected_service_group > 0

  # Also trigger everything after page (re)load, e.g. validation fail
  select_sgc.trigger 'change'
