# Polls the path specified within an HTML elements (#static_document) data-document-id attribute every setTimeout interval milliseconds.
# This path should return a link with the URL of the StaticDocument that was requested by the user.

# Destroy modal content once it gets closed
$(document).on 'turbolinks:load', ->
  $('body').on 'hidden.bs.modal', '#static_document_modal', (e) ->
    $(this).removeData('bs.modal')
    $('.modal-content', this).empty()
    return


# Append a modal dialog div to the HTML body which can be shown in case an #export_static_document_link is on a page an populated with the request path from above
$(document).on 'turbolinks:load', ->
  if $('#export_xlsx_document_link').length > 0 || $('#export_pdf_document_link').length > 0
    # Create the modal box
    modal_html = """
    <div id="static_document_modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="confirmation_modal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
        </div>
      </div>
    </div>
    """
    $modal_html = $(modal_html)
    $("body").append($modal_html)
    # Prevent the original link from working
    # return false

$(document).on 'turbolinks:load', ->
  $('#static_document_modal').on 'show.bs.modal', (event) ->
    modal = $(this)
    button = event.relatedTarget
    modal.find('.modal-content').load(button.href)
    return
