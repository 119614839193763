import 'ekko-lightbox'

// Ekko lightbox
$(document).on('click', '[data-toggle="lightbox"]', function(event) {
  event.preventDefault()
  $(this).ekkoLightbox({
    alwaysShowClose: true,
    maxWidth: 1024,
    loadingMessage: '<div class="async_loader ekko-lightbox-loader async_loader_full"><div><div></div><div></div></div></div>'
  })
})
