import I18n from '../i18n-settings.js.erb'

// Calculation * 100 and then / 100 is needed because of JavaScript idosyncracy regarding floats
const round = (value, decimals) => Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)

const calculateUpper = function(startValue) {
  if (startValue.includes(',')) {
    startValue = startValue.replace(',', '.')
  }
  let upperValue = round((parseFloat(startValue) + 0.1), 1)
  upperValue = upperValue.toLocaleString(I18n.locale)
  return upperValue
}

// Lights
$(document).on('turbolinks:load', () => {
  $('#inspection_settings_inspection_evaluation_lights_attributes_red_to').on('input', function() {
    $('#inspection_settings_inspection_evaluation_lights_attributes_yellow_from').val(calculateUpper($(this).val()))
  })
})

$(document).on('turbolinks:load', () => {
  $('#inspection_settings_inspection_evaluation_lights_attributes_yellow_to').on('input', function() {
    $('#inspection_settings_inspection_evaluation_lights_attributes_green_from').val(calculateUpper($(this).val()))
  })
})

// Grades
$(document).on('turbolinks:load', () => {
  $('#inspection_settings_inspection_evaluation_grades_attributes_five_to').on('input', function() {
    $('#inspection_settings_inspection_evaluation_grades_attributes_four_from').val(calculateUpper($(this).val()))
  })
})

$(document).on('turbolinks:load', () => {
  $('#inspection_settings_inspection_evaluation_grades_attributes_four_to').on('input', function() {
    $('#inspection_settings_inspection_evaluation_grades_attributes_three_from').val(calculateUpper($(this).val()))
  })
})

$(document).on('turbolinks:load', () => {
  $('#inspection_settings_inspection_evaluation_grades_attributes_three_to').on('input', function() {
    $('#inspection_settings_inspection_evaluation_grades_attributes_two_from').val(calculateUpper($(this).val()))
  })
})

$(document).on('turbolinks:load', () => {
  $('#inspection_settings_inspection_evaluation_grades_attributes_two_to').on('input', function() {
    $('#inspection_settings_inspection_evaluation_grades_attributes_one_from').val(calculateUpper($(this).val()))
  })
})

// Show/Hide RatingSystem based on radio button
$(document).on('turbolinks:load', () => {
  $('input[name=\'inspection_settings[evaluation_system]\']').click(function() {
    if ($('#inspection_settings_evaluation_system_evaluation_lights').is(':checked')) {
      $('.lights_partial').removeClass('d-none')
      $('.lights_partial').addClass('d-block')
      $('.grades_partial').removeClass('d-block')
      $('.grades_partial').addClass('d-none')
    } else {
      $('.grades_partial').removeClass('d-none')
      $('.grades_partial').addClass('d-block')
      $('.lights_partial').removeClass('d-block')
      $('.lights_partial').addClass('d-none')
    }
  })
})
