# Set dropdowns and weight input for new Notice view
# ---------------------------------------------------------------------

$(document).on 'turbolinks:load', ->

  select_estate         = $('#notice_estate')
  select_building       = $('#notice_building')
  select_floor          = $('#notice_floor_id')
  select_contact_person = $('#notice_contact_person_id')
  select_notice_kind    = $('#notice_notice_kind_id')
  buildings             = select_building.html()
  floors                = select_floor.html()
  contact_persons       = select_contact_person.html()

  cost_center_names     = $('#notice_notice_kind_cost_center').data('idcostcenters')

  empty_option = "<option selected='selected'></option>"

  # Disable secondary/tertiary selects of cascades
  select_building.prop('disabled', true) if select_building.val() == ""
  select_floor.prop('disabled', true) if select_building.val() == ''
  select_contact_person.prop('disabled', true) if select_contact_person.val() == ''

  # Populate buildings
  selected_building = select_building.find('option:selected').val()
  select_estate.change ->
    selected_estate = select_estate.find('option:selected').val()
    options = $(buildings).filter("optgroup[label='#{selected_estate}']").html()
    number_of_buildings = if options then (options.match(/value/g) || []).length else 0
    if options
      select_building.prop('disabled', false)
      select_building.html(options)
      # If nothing is selected yet, add empty option, so User has to select right value first, but only when more than one Building is selectable
      unless selected_building > 0 || number_of_buildings == 1
        select_building.prepend(empty_option)
      select_floor.prop('disabled', true) if select_building.val() == ''
      if select_floor.val() != null && select_floor.val() != ''
        select_contact_person.prop('disabled', true)
        select_contact_person.prepend(empty_option)
    else
      select_building.empty()
    populate_select_floors()

  # Populate floors
  select_building.change ->
    select_floor.prop('disabled', false) if select_building.val() != ''
    populate_select_floors()

  selected_floor = select_floor.find('option:selected').val()
  populate_select_floors = ->
    building = select_building.find(':selected').val()
    options = $(floors).filter("optgroup[label='#{building}']").html()
    number_of_floors = if options then (options.match(/value/g) || []).length else 0
    if options
      select_floor.prop('disabled', false)
      select_floor.html(options)
      # If nothing is selected yet, add empty option, so User has to select right value first, but only when more than one Floor is selectable
      unless selected_floor > 0 || number_of_floors == 1
        select_floor.prepend(empty_option)
    else
      select_floor.empty()
    if (select_floor.val() != null && select_floor.val() != '')
      select_floor.trigger 'change'

  select_floor.change ->
    select_contact_person.prop('disabled', false) if select_estate.val() != ''
    populate_select_contact_persons()

  selected_contact_person = select_contact_person.find('option:selected').val() # only works here, don't know why
  # Populate contact_persons
  populate_select_contact_persons = ->
    estate = select_estate.find(':selected').val()
    options = $(contact_persons).filter("optgroup[label='#{estate}']").html()
    number_of_contact_persons = if options then (options.match(/value/g) || []).length else 0
    if options
      select_contact_person.html(options)
      # If nothing is selected yet, add empty option, so User has to select right value first, but only when more than one ContactPerson is selectable
      unless selected_contact_person > 0 || number_of_contact_persons == 1
        select_contact_person.prepend(empty_option)
    else
      select_contact_person.empty()

  # Populate cost_center
  select_notice_kind.change ->
    set_notice_notice_kind_cost_center()

  # Change value of disabled cost_center_showonly text input or enable cost_center input field, if selected Notice::Kind has no cost_center set
  set_notice_notice_kind_cost_center = ->
    notice_kind_id = select_notice_kind.find(":selected").val()
    for id, name of cost_center_names
      if id == notice_kind_id
        notice_notice_kind_cost_center_name = name
    if notice_notice_kind_cost_center_name
      $('#cost_center_showonly').show()
      $('#notice_cost_center').hide()
      # Set disabled text field
      $('#cost_center_showonly').val(notice_notice_kind_cost_center_name)
      # Set hidden field
      $('#notice_cost_center').val(notice_notice_kind_cost_center_name)
    else
      if select_notice_kind.val() == '' # Nothing selected yet
        $('#cost_center_showonly').show()
        $('#notice_cost_center').hide()
      else
        $('#cost_center_showonly').hide()
        $('#notice_cost_center').show()
      # Clear that stuff
      $('#cost_center_showonly').val('')
      form_resubmit_value = $('#notice_cost_center').data('form-resubmit-cost-center') # Get user selected value after form validation has failed
      $('#notice_cost_center').val(form_resubmit_value)


  # Also trigger everything after page (re)load, e.g. validation fail
  set_notice_notice_kind_cost_center()
  select_estate.trigger 'change'


# Disable save button on Notice rating form until all ratings are selected
# ---------------------------------------------------------------------

$(document).on 'turbolinks:load', ->
  $('form.rate_notice button[type="submit"]').prop('disabled', true)
  $('form.rate_notice input[type="radio"]').change ->
    if $('form.rate_notice input[type="radio"]:checked').length == $('form.rate_notice .rating.form').length
      $('form.rate_notice button[type="submit"]').prop('disabled', false)
    else
      $('form.rate_notice button[type="submit"]').prop('disabled', true)


# Show rating when state_change accept is selected
# ------------------------------------------------

$(document).on 'turbolinks:load', ->
  $('#notice_message_state_change').change ->
    if $(this).val() == 'accept'
      $('.rate_notice_fields').show()
      # disable_submit_button_on_rating_form()
    else
      $('.rate_notice_fields').hide()
