$(document).on 'turbolinks:load', ->

  select_estate   = $('#floor_estate')
  select_building = $('#floor_building_id')
  buildings = $('#floor_building_id').html()

  # Disable secondary select of cascade
  select_building.prop('disabled', true) if select_estate.val() == '' && select_building.val() == ''

  select_estate.change ->
    estate = select_estate.find(':selected').text()
    options = $(buildings).filter("optgroup[label='#{estate}']").html()
    if options
      select_building.prop('disabled', false)
      select_building.html(options)
    else
      select_building.empty()

  # Trigger change when Estate is preselected, so optgroups get filtered
  select_estate.trigger 'change' if select_estate.val() != ''
