$(document).on 'turbolinks:load', ->

  # Set datepicker according to selected time_period
  # ---------------------------------------------------------------------

  select_time_period = $('.output #search_time_period')

  datepicker_daily   = $('.output .datepickers .datepicker_daily')
  datepicker_monthly = $('.output .datepickers .datepicker_monthly')
  datepicker_yearly  = $('.output .datepickers .datepicker_yearly')

  select_time_period.change ->
    time_period = select_time_period.find(':selected').val()
    switch time_period
      when 'daily'
        datepicker_daily.show().find('*').attr('disabled', false)
        datepicker_monthly.hide().find('*').attr('disabled', true)
        datepicker_yearly.hide().find('*').attr('disabled', true)
      when 'monthly'
        datepicker_monthly.show().find('*').attr('disabled', false)
        datepicker_daily.hide().find('*').attr('disabled', true)
        datepicker_yearly.hide().find('*').attr('disabled', true)
      when 'yearly'
        datepicker_yearly.show().find('*').attr('disabled', false)
        datepicker_daily.hide().find('*').attr('disabled', true)
        datepicker_monthly.hide().find('*').attr('disabled', true)
      # else datepicker_daily.show()

  # Also trigger everything after page (re)load, e.g. validation fail
  select_time_period.trigger 'change'
