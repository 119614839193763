$(document).on 'turbolinks:load', ->

  select_service_group_category   = $('#service_service_group_category')
  select_service_group = $('#service_service_group_id')
  service_groups = $('#service_service_group_id').html()


  # Disable secondary select of cascade
  select_service_group.prop('disabled', true) if select_service_group_category.val() == '' && select_service_group.val() == ''

  select_service_group_category.change ->
    service_group_category = select_service_group_category.find(':selected').text()
    options = $(service_groups).filter("optgroup[label='#{service_group_category}']").html()
    if options
      select_service_group.prop('disabled', false)
      select_service_group.html(options)
    else
      select_service_group.empty()

  # Trigger change when SGC is preselected, so optgroups get filtered
  select_service_group_category.trigger 'change' if select_service_group_category.val() != ''
