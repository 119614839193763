import I18n from './i18n-settings.js.erb'
import select2 from 'select2/dist/js/select2'
import 'select2/dist/js/i18n/de'

// Activate select2 dropdowns
// ---------------------------------------------------------------------

// Custom matcher, that also works with optgroups
const matcher = (params, data) => {
  // Always return the object if there is nothing to compare
  if ($.trim(params.term) === '') {
    return data
  }
  const original = data.text.toUpperCase()
  const term = params.term.toUpperCase()
  // Check if the text contains the term
  if (original.indexOf(term) > -1) {
    return data
  }
  // Do a recursive check for options with children
  if (data.children && (data.children.length > 0)) {
    // Clone the data object if there are children
    // This is required as we modify the object to remove any non-matches
    const match = $.extend(true, {}, data)
    // Check each child of the option
    let c = data.children.length - 1
    while (c >= 0) {
      const child = data.children[c]
      const matches = matcher(params, child)
      // If there wasn't a match, remove the object in the array
      if (matches === null) {
        match.children.splice(c, 1)
      }
      c--
    }
    // If any children matched, return the new object
    if (match.children.length > 0) {
      return match
    }
    // If there were no matching children, check just the plain object
    return matcher(params, match)
  }
  // If it doesn't contain the term, don't return anything
  return null
}

// That ugly hack is necessary to stop select2 from duplicating itself when using the back and next buttons in the browser when Turbolinks is active stackoverflow.com/questions/36497723/select2-with-ajax-gets-initialized-several-times-with-rails-turbolinks-events
document.addEventListener('turbolinks:before-cache', () => {
  $('.select2-hidden-accessible').select2('destroy')
})

$(document).on('turbolinks:load', () => {
  $('.select2').select2({
    theme: 'bootstrap',
    placeholder: I18n.t('navigation.select'),
    width: '100%',
    matcher
  })
})

$(document).on('turbolinks:load', () => {
  $('.select2-without-placeholder').select2({
    theme: 'bootstrap',
    width: '100%',
    matcher
  })
})

$(document).on('turbolinks:load', () => {
  $('.select2-without-search').select2({
    enoughRoomAbove: true,
    theme: 'bootstrap',
    placeholder: I18n.t('navigation.select'),
    width: '100%',
    minimumResultsForSearch: Infinity
  })
})
