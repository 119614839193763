$(document).on 'turbolinks:load', ->

  select_estate         = $('#ticket_estate')
  select_building       = $('#ticket_building')
  select_floor          = $('#ticket_floor_id')
  select_ticket_kind    = $('#ticket_ticket_kind_id')
  buildings             = select_building.html()
  floors                = select_floor.html()

  cost_center_names     = $('#ticket_cost_center').data('idcostcenters')

  empty_option = "<option selected='selected'></option>"

  # Disable secondary/tertiary selects of cascades
  select_building.prop('disabled', true) if select_building.val() == ""
  select_floor.prop('disabled', true) if select_building.val() == ''

  # Populate buildings
  selected_building = select_building.find('option:selected').val()
  select_estate.change ->
    selected_estate = select_estate.find('option:selected').val()
    options = $(buildings).filter("optgroup[label='#{selected_estate}']").html()
    number_of_buildings = if options then (options.match(/value/g) || []).length else 0
    if options
      select_building.prop('disabled', false)
      select_building.html(options)
      # If nothing is selected yet, add empty option, so User has to select right value first, but only when more than one Building is selectable
      unless selected_building > 0 || number_of_buildings == 1
        select_building.prepend(empty_option)
      select_floor.prop('disabled', true) if select_building.val() == ''
    else
      select_building.empty()
    populate_select_floors()

  # Populate floors
  select_building.change ->
    select_floor.prop('disabled', false) if select_building.val() != ''
    populate_select_floors()

  selected_floor = select_floor.find('option:selected').val()
  populate_select_floors = ->
    building = select_building.find(':selected').val()
    options = $(floors).filter("optgroup[label='#{building}']").html()
    number_of_floors = if options then (options.match(/value/g) || []).length else 0
    if options
      select_floor.prop('disabled', false)
      select_floor.html(options)
      # If nothing is selected yet, add empty option, so User has to select right value first, but only when more than one Floor is selectable
      unless selected_floor > 0 || number_of_floors == 1
        select_floor.prepend(empty_option)
    else
      select_floor.empty()
    if (select_floor.val() != null && select_floor.val() != '')
      select_floor.trigger 'change'

  # Populate cost_center
  select_ticket_kind.change ->
    set_ticket_ticket_kind_cost_center()

  set_ticket_ticket_kind_cost_center = ->
    ticket_kind_id = select_ticket_kind.find(":selected").val()
    for id, name of cost_center_names
      if id == ticket_kind_id
        ticket_ticket_kind_cost_center_name = name
    if ticket_ticket_kind_cost_center_name
      # Set disabled text field
      $('#cost_center_showonly').val(ticket_ticket_kind_cost_center_name)
      # Set hidden field
      $('#ticket_cost_center').val(ticket_ticket_kind_cost_center_name)
    else
      # Clear that stuff
      $('#cost_center_showonly').val('')
      $('#ticket_cost_center').val('')


  # Also trigger everything after page (re)load, e.g. validation fail
  set_ticket_ticket_kind_cost_center()
  select_estate.trigger 'change'
