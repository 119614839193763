$(document).on('turbolinks:load', () => {
  // Set dropdowns for new check_ins
  // -------------------------------
  const selectEstate = $('#check_in_estate')
  const selectBuilding = $('#check_in_building')
  const selectFloor = $('#check_in_floor_id')
  const buildings = selectBuilding.html()
  const floors = selectFloor.html()

  const emptyOption = "<option value='' selected='selected'></option>"

  // Disable secondary/tertiary selects of cascades
  if (selectEstate.val() === '') { selectBuilding.prop('disabled', true) }
  if (selectBuilding.val() === '') { selectFloor.prop('disabled', true) }

  // Populate buildings
  const selectedBuilding = selectBuilding.find('option:selected').val()
  selectEstate.on('change', function() {
    const selectedEstate = selectEstate.find('option:selected').val()
    const options = $(buildings).filter(`optgroup[label='${selectedEstate}']`).html()
    const numberOfBuildings = options ? (options.match(/value/g) || []).length : 0
    if (options) {
      selectBuilding.prop('disabled', false)
      selectBuilding.html(options)
      // If nothing is selected yet, add empty option, so User has to select right value first, but only when more than one Building is selectable
      if (!(selectedBuilding > 0) && (numberOfBuildings !== 1)) {
        selectBuilding.prepend(emptyOption)
      }
      if (selectBuilding.val() === '') { selectFloor.prop('disabled', true) }
    } else {
      selectBuilding.empty()
    }
    return populateSelectFloor()
  })

  // Populate floors
  selectBuilding.on('change', function() {
    if (selectBuilding.val() !== '') { selectFloor.prop('disabled', false) }
    return populateSelectFloor()
  })

  const populateSelectFloor = () => {
    const building = selectBuilding.find(':selected').val()
    const options = $(floors).filter(`optgroup[label='${building}']`).html()
    const floor = selectFloor.find('option:selected').val()
    const numberOfFloors = options ? (options.match(/value/g) || []).length : 0
    if (options) {
      selectFloor.prop('disabled', false)
      selectFloor.html(options)
      // If nothing is selected yet, add empty option, so User has to select right value first, but only when more than one Floor is selectable
      if (!(floor > 0) && (numberOfFloors !== 1)) {
        return selectFloor.prepend(emptyOption)
      }
    } else {
      return selectFloor.empty()
    }
  }

  // Also trigger everything after page (re)load, e.g. validation fail
  if (selectEstate) {
    selectEstate.trigger('change')
  }
})
