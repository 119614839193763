$(document).on 'turbolinks:load', ->
  # Select all
  $('.checkbox_select_all').change ->
    $(this).closest('.card').find('.custom-control input').prop 'checked', $(this).prop('checked')
    return
  return

$(document).on 'turbolinks:load', ->
  # Hide Estate/Service Group Category selection for Controller and Manager
  $('#role_selection').click ->
    if $('#new_role_controller').is(':checked') || $('#new_role_manager').is(':checked')
      # removeClass workaround for https://github.com/twbs/bootstrap/issues/9237
      if $('#estate_service_group_category_selection').hasClass('d-flex')
        $('#estate_service_group_category_selection').removeClass('d-flex').show()
      $('#estate_service_group_category_selection').hide('fast')
    else
      # removeClass workaround for https://github.com/twbs/bootstrap/issues/9237
      if $('#estate_service_group_category_selection').hasClass('d-none')
        $('#estate_service_group_category_selection').removeClass('d-none').hide()
      $('#estate_service_group_category_selection').show('fast')
