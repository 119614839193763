import { CustomUploader } from './custom_uploader'
import I18n from './i18n-settings.js.erb'
const reduce = require('image-blob-reduce')()

// We have a file upload field in a div called .custom-file
// 1. When a file gets attached to it, we hide .custom-file and show a
//    progress bar
// 2. When the upload finishes, we
//    - hide the progress bar
//    - add a div .finished-upload, which is a modified copy of .custom-
//      file that shows the file and contains a hidden field with the
//      file params
//    - remove the hidden field from .custom-file
//    - show a link to add another file (if multipe uploads are enabled
//      and there are less than 10 uploads already in this .form-group)
// 3. When the link to add another file is clicked,
//    - we show the original file input again, which was hidden until now
//    - we hide that very same link again
// 4. Repeat from step one, to add more uploads
// 5. When the trash bin icon on the .finished-upload is clicked, we
//    remove the respective .finished-upload div
// 6. When the last .finished-upload div gets removed, we show the file
//    upload field again
//
// If the form redisplays, for example because of validation errors, the
// state after step 2 should be displayed (without invoking any JS)

document.addEventListener('turbolinks:load', () => {
  // Handle change, e.g. User attaches a file
  const inputs = Array.from(document.querySelectorAll('.custom-file-input')) // Array.from needed as a Tribute to IE11

  // Bind to file selection
  if (inputs.length > 0) {
    inputs.forEach(input => {
      addUploadEventListener(input)
    })
  }

  // Handle reset
  const customFileGroupsFinished = Array.from(document.querySelectorAll('.custom-file.finished-upload'))
  if (customFileGroupsFinished.length > 0) {
    customFileGroupsFinished.forEach(customFileGroup => {
      customFileGroup.addEventListener('click', resetUploadField)
    })
  }

  // Handle "Add another upload" link
  const customFileGroups = Array.from(document.querySelectorAll('.custom-file'))
  if (customFileGroups.length > 0) {
    customFileGroups.forEach(customFileGroup => {
      const formGroup = customFileGroup.parentNode
      const addUploadLink = formGroup.querySelector('.add-upload-link a')
      if (addUploadLink) {
        // Event listener
        addUploadLink.addEventListener('click', event => {
          event.preventDefault()
          // Just show original upload field
          formGroup.querySelector('.custom-file.original').classList.remove('d-none')
          event.target.parentNode.classList.add('invisible')
        })
      }
    })
  }
})

addEventListener('direct-upload:initialize', event => {
  const { target, detail } = event
  const { id, file } = detail

  const customFileGroup = target.parentNode

  customFileGroup.classList.add('d-none')

  customFileGroup.insertAdjacentHTML('beforebegin', `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename">${file.name}</span>
    </div>
  `)
})

addEventListener('direct-upload:start', event => {
  const { id, file } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove('direct-upload--pending')

  // Disable submit button
  document.querySelector('button.crud_action_create').disabled = true
  // Add preview image
  element.insertAdjacentHTML('afterbegin', '<div class="upload-preview"></div>')
  previewImage(file, element)
})

addEventListener('direct-upload:progress', event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
})

addEventListener('direct-upload:error', event => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add('direct-upload--error')
  element.setAttribute('title', error)
})

addEventListener('direct-upload:end', event => {
  const { target, detail } = event
  const { id, file } = detail
  const progress = document.getElementById(`direct-upload-${id}`)
  const uploadFileGroup = target.parentNode
  const formGroup = uploadFileGroup.parentNode
  const addUploadLink = formGroup.querySelector('.add-upload-link')

  // Enable submit button
  document.querySelector('button.crud_action_create').disabled = false

  // Hide progress bar
  progress.parentNode.removeChild(progress)

  // Show finished-upload
  const finishedUploadGroup = uploadFileGroup.cloneNode(true)
  finishedUploadGroup.classList.add('finished-upload')
  // Add preview image
  finishedUploadGroup.querySelector('.custom-file-label').insertAdjacentHTML('afterbegin', '<div class="upload-preview"></div>')
  previewImage(file, finishedUploadGroup)
  // Display and insert that shit
  finishedUploadGroup.classList.remove('d-none')
  finishedUploadGroup.classList.remove('original')
  uploadFileGroup.parentNode.insertBefore(finishedUploadGroup, uploadFileGroup)

  // Remove hidden field from uploadFileGroup
  const hiddenInput = uploadFileGroup.querySelector('.cache')
  hiddenInput.parentNode.removeChild(hiddenInput)

  // Show "Add another upload" link if there are not more then 10 uploads
  // already attached
  if (addUploadLink && formGroup.querySelectorAll('.custom-file').length <= 11) {
    addUploadLink.classList.remove('invisible')
  }

  // Reset file field label
  const placeholderText = uploadFileGroup.querySelector('.custom-file-input').dataset.fallbackPlaceholder
  uploadFileGroup.querySelector('.custom-file-label').innerHTML = placeholderText

  // Handle file field reset
  finishedUploadGroup.addEventListener('click', resetUploadField, { once: true })
})

function resetUploadField(event) {
  const { target } = event
  event.preventDefault()
  const customFileGroup = target.parentNode
  const formGroup = customFileGroup.parentNode

  // Remove hidden input
  const hiddenInput = customFileGroup.querySelector('.cache')
  hiddenInput.parentNode.removeChild(hiddenInput)

  // Remove input if clone or reset, if original
  if (customFileGroup.classList.contains('finished-upload')) {
    customFileGroup.parentNode.removeChild(customFileGroup)
  }

  if (formGroup.querySelectorAll('.custom-file').length <= 1) {
    // Display hidden original .custom-file
    const originalCustomFileGroup = formGroup.querySelector('.custom-file.original')
    originalCustomFileGroup.classList.remove('d-none')
    // Restore label text
    const placeholderText = originalCustomFileGroup.querySelector('.custom-file-input').dataset.fallbackPlaceholder
    originalCustomFileGroup.querySelector('.custom-file-label').innerHTML = placeholderText
    // Hide "Add another upload" link
    const addUploadLink = formGroup.querySelector('.add-upload-link')
    if (addUploadLink) {
      addUploadLink.classList.add('invisible')
    }
  }
}

function addUploadEventListener(input) {
  input.addEventListener('change', event => {
    const { target } = event

    // Set filename on label
    const fileName = target.value.split('\\').pop()
    target.nextSibling.classList.add('selected')
    target.nextSibling.innerHTML = fileName

    const passToUpload = (input, file) => {
      const uploader = new CustomUploader(input, file)
      uploader.start(file)
    }

    Array.from(input.files).forEach(file => {
      if (isInFileNameBlackList(file.name)) {
        alert(I18n.t('errors.file_invalid'))
      } else if (file.type.match(/image.*/)) {
        // Check if the file is an image, attempt to validate it and then compress it.
        const validateImage = (file) => {
          return new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = () => resolve(true) // Image is valid
            img.onerror = () => reject(new Error('Image is corrupted or invalid')) // Image is corrupted or invalid

            const url = URL.createObjectURL(file)
            img.src = url
          })
        }

        validateImage(file)
          .then(isValid => {
            if (isValid && input.dataset.compression) {
              // Image is valid, proceed with resizing and compression
              const config = {
                max: 1500,
                unsharpAmount: 80,
                unsharpRadius: 0.6,
                unsharpThreshold: 2
              }

              try {
                reduce
                  .toBlob(file, config)
                  .then(imageBlob => {
                    imageBlob.lastModifiedDate = new Date()
                    imageBlob.name = file.name

                    passToUpload(input, imageBlob)
                  })
              } catch {
                passToUpload(input, file)
              }
            } else {
              // If image validation failed or compression is disabled, upload the original file
              passToUpload(input, file)
            }
          })
          .catch(() => {
            alert(I18n.t('errors.file_corrupted'))
          })
      } else {
        passToUpload(input, file)
      }
    })
    // you might clear the selected files from the input
    input.value = null
    target.nextSibling.innerHTML = fileName // IE11 wil end up with empty label otherwise …
  })
}

function previewImage(file, container) {
  const imageType = /^^image\/|application\/pdf/

  if (imageType.test(file.type)) {
    const img = document.createElement('img')
    img.classList.add('obj')
    img.file = file
    container.querySelector('.upload-preview').appendChild(img)

    const reader = new FileReader()
    reader.onload = (function(aImg) { return function(e) { aImg.src = e.target.result } })(img)
    reader.readAsDataURL(file)
  }
}

function isInFileNameBlackList(fileName) {
  // Match any file name, that begins with 'MicrosoftTeams-image'
  // if (fileName.match(/^MicrosoftTeams-image/)) {
  //   return true
  // }
  // Match any .dng (Adobe Digital Negative) file
  if (fileName.match(/\.dng$/)) {
    return true
  }
  // Match any .ico file
  if (fileName.match(/\.ico$/)) {
    return true
  }

  return false
}
