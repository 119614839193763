$(document).on('turbolinks:load', () => {
  // contact_type
  const contactType = $('#contact_person_contact_type')
  let fieldsExternal
  let fieldsInternal

  // Hide the #contact-type-internal if contactType is not internal
  const showContactType = () => {
    if (contactType.val() == 'internal') {
      $('#contact-type-internal').append(fieldsInternal)
      fieldsExternal = $('#contact-type-external').children().detach()
    } else if (contactType.val() == 'external') {
      $('#contact-type-external').append(fieldsExternal)
      fieldsInternal = $('#contact-type-internal').children().detach()
    }
  }

  showContactType()

  contactType.on('change', showContactType)

  // E-Mail Addresses
  const addButton = $('#contact-person-emails a.add_fields')

  // Dont allow to add more than 3 email addresses for a ContactPerson
  const check_to_hide_or_show_add_link = () => {
    if ($('.contact-person-email-fields:visible').length >= 3) {
      addButton.hide()
    } else {
      addButton.show()
    }
  }

  // Hide the delete button group, if there is only one email address left
  const check_to_hide_or_show_delete_button = () => {
    if ($('.contact-person-email-fields:visible').length == 1) {
      $('.contact-person-email-fields .input-group-append').hide()
    } else {
      $('.contact-person-email-fields .input-group-append').show()
    }
  }

  // On page load
  check_to_hide_or_show_add_link()
  check_to_hide_or_show_delete_button()

  // After adding a new email address field
  $('#contact-person-emails').on(
    'cocoon:after-insert',
    (_e, _insertedItem, _originalEvent) => {
      check_to_hide_or_show_add_link()
      check_to_hide_or_show_delete_button()
    }
  )

  // After removing an email address field
  $('#contact-person-emails').on(
    'cocoon:after-remove',
    (_e, _insertedItem, _originalEvent) => {
      check_to_hide_or_show_add_link()
      check_to_hide_or_show_delete_button()
    }
  )
})
