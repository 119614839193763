import geolocator from 'geolocator'

// Get and show location
// ---------------------------------------------------------------------

geolocator.config({
  language: 'de',
  google: {
    version: '3',
    // key: 'AIzaSyAOSPRPpInF_vAD1JDC80yPIJ-Td2oEekA' # DEV only!
    key: 'AIzaSyA90IoPWk5Ov0fySmENwMTMAVfXLmMwwKU'
  }
}) // production

// Function to get accurate current position
// github.com/onury/geolocator
const getLocation = function() {
  // geolocator.locate( successCallback, [errorCallback], [fallbackToIP], [html5Options], [mapCanvasId] )
  const geolocatorOptions = {
    enableHighAccuracy: true,
    timeout: 15000,
    maximumWait: 10000,     // max wait time for desired accuracy
    maximumAge: 0,          // disable cache
    desiredAccuracy: 10,    // meters
    fallbackToIP: false,
    addressLookup: true,
    timezone: false
  }
    // map: 'map'
  // Show loader
  onGeoTracking()
  // Locate
  geolocator.locate(geolocatorOptions, function(err, location) {
    if (err) {
      // return console.log(err)
      onGeoError()
    }
    onGeoSuccess(location)
    // console.log location
  })
}

// Function that gets run when position was found
var onGeoSuccess = function(location) {
  var latitude = location && location.coords.latitude || 0
  var longitude = location && location.coords.longitude || 0
  // console.log(location)
  // Populate form values
  $('#time_tracking_latitude').val(latitude)
  $('#time_tracking_longitude').val(longitude)
  // Show confirmation
  $('#time_tracking_location_false').hide()
  $('#time_tracking_location_progress').hide()
  // Update static map
  const map_source = `https://api.mapbox.com/styles/v1/mapbox/light-v10/static/pin-s+27b6af(${longitude},${latitude}/${longitude},${latitude},15/430x270@2x?access_token=pk.eyJ1IjoidzN3ZXJrZSIsImEiOiIwM0s5MkRjIn0.VMPxOOW9BwuFMHilUc7sSg`
  $("#map img").attr("src", map_source)
  // Show tracked address
  $('#time_tracking_current_location').show()
  $('#map').show()
  $('#time_tracking_current_location .output').text(location?.formattedAddress)
}

// Function that gets run when position was not found
var onGeoError = function(error) {
  $('#time_tracking_location_progress').hide()
  return $('#time_tracking_location_false').css('display', 'block')
}

// Function that gets run while position finding is in progress
var onGeoTracking = function() {
  $('#time_tracking_location_false').hide()
  return $('#time_tracking_location_progress').css('display', 'block')
}

// Location finder gets activated on last TimeTracking creation form step
$(function() {
  if ($('body.timetrackings.build .end').length > 0) {
    getLocation()
    // window.location.reload()
    return false
  }
})

// Button/link to re-run location tracking
$(() =>
  $('.rerun_get_location').click(function(event) {
    event.preventDefault()
    window.location.reload(false)
  })
)

// Button/link to remove location
$(() =>
  $('.destroy_location').click(function(event) {
    event.preventDefault()
    $('#time_tracking_location').hide()
    $('#time_tracking_latitude').val('')
    $('#time_tracking_longitude').val('')
    $('#time_tracking_current_location').hide()
    $('#time_tracking_no_location_submitted').show()
    $('#map').hide()
  })
)


// Elapsed time counter
// ---------------------------------------------------------------------

// Calls the counter (each 1000ms/1s)
const startCount = (function() {
  let executed = false
  return function() {
    if (!executed) { // Make sure this is only called once at all
      let timer
      executed = true
      return timer = setInterval(count, 1000)
    }
  }
})()

// Parse the current time and count up (+ 1000ms/1s)
var count = function() {
  const time_shown = $('#time_tracking_timer').text()
  const time_chunks = time_shown.split(':')
  let hour = undefined
  let mins = undefined
  let secs = undefined
  hour = Number(time_chunks[0])
  mins = Number(time_chunks[1])
  secs = Number(time_chunks[2])
  secs++
  if (secs === 60) {
    secs = 0
    mins = mins + 1
  }
  if (mins === 60) {
    mins = 0
    hour = hour + 1
  }
  if (hour === 25) {
    hour = 1
  }
  $('#time_tracking_timer').text(zeroPad(hour) + ':' + zeroPad(mins) + ':' + zeroPad(secs))
}

// Accepts interval in milliseconds and returns it in format HH:MM:SS
const timeFormatted = function(interval) {
  const elapsed = new Date(interval)
  const hour = elapsed.getHours()
  const mins = elapsed.getMinutes()
  const secs = elapsed.getSeconds()
  return zeroPad(hour) + ":" + zeroPad(mins) + ":" + zeroPad(secs)
}

// Zero pads the time chunks (HH:MM:SS)
var zeroPad = function(digit) {
  let zpad = digit + ''
  if (digit < 10) {
    zpad = `0${zpad}`
  }
  return zpad
}

// Live counter for elapsed time of current TimeTracking
// Needs an HTML element #time_tracking_timer which contains a time (format HH:MM:SS) that will be counted up each second
window.onload = function() {
  if ($('#time_tracking_timer').length > 0) {
    startCount()
    return
  }
}

// Recalculates elapsed time when the App regains focus in the browser, e.g. User unlocks his Phone or switches back to a tab where CGX is open by adding the difference of the current time and the time when the current page loaded to the original time embedded in the page's HTML
$(function() {
  const original_time_formatted = $('#time_tracking_timer').text()
  const page_loaded_time = Date.now()

  $(window).focus(function() {
    if ($('#time_tracking_timer').length > 0) {
      const current_time = Date.now()
      const elapsed_time_on_current_page = current_time - page_loaded_time

      const time_chunks = original_time_formatted.split(':')
      const original_time = new Date(0, 0, 0, time_chunks[0], time_chunks[1], time_chunks[2]).getTime()

      const elapsed_time = original_time + elapsed_time_on_current_page

      $('#time_tracking_timer').text(timeFormatted(elapsed_time))
      return
    }
  })

  // Set dropdowns for first page of new TimeTracking
  // ---------------------------------------------------------------------

  const select_estate   = $('#time_tracking_estate_id')

  // Preselect primary fields, if they contain only one option and nothing is selected already
  if ((select_estate.find('option').length <= 2) && (select_estate.find('option:selected').val() === '')) {
    const first_value = select_estate.find('option:nth-child(2)').val()
    select_estate.val(first_value)
    return select_estate.trigger('change')
  }
})
